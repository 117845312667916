// src/js/main.js
import { AudioCapture } from './AudioCapture';
import { SpeechRecognition } from './SpeechRecognition';
import { ContentSafety } from './ContentSafety';
import { SkylarAPI } from './SkylarAPI';
import { VolumeMeter } from './Control/VolumeMeter';
import { TextToSpeech } from './TextToSpeech';
import TabNav from './TabNav';


import '../css/styles.css';

class App {
    constructor() {
        this.audioCapture = new AudioCapture();
        this.speechRecognition = new SpeechRecognition(process.env.AZURE_SPEECH_KEY, process.env.AZURE_SPEECH_REGION);
        this.contentSafety = new ContentSafety(process.env.AZURE_CONTENT_SAFETY_ENDPOINT, process.env.AZURE_CONTENT_SAFETY_KEY);
        this.textToSpeech = new TextToSpeech(process.env.ELEVENLABS_API_KEY, process.env.ELEVENLABS_VOICE_ID );
        this.api = new SkylarAPI();
        this.tabnav = new TabNav();
        this.responseAudio = new Audio();

        this.audioBlob = null;
        this.chatgptResponseAudio = null;

        this.chatHistory = [];
        
        this.interviewStarted = false;


        this.tabnav.init();
        this.setupElements();

    }

    setupElements() {

        const elements = {
            initMicButton: 'init_mic',
            volumeMeter: 'volume_meter',
//            capturePlayer: 'capture_player',
//            responsePlayer: 'response_player',
//            recognizedTextElement: 'recognizedText',
//            chatgptResponseElement: 'chatgptResponse',
//            contentSafetyStatus: 'contentSafetyStatus',
            statusElement: 'status',
            chatHistoryElement: 'chat-history',
//            restartButton: 'restart_session'
        };

        for ( const prop in elements ) {
            this[prop] = document.getElementById( elements[prop] );
        }

        this.bindEventListeners();

        // Start mic if possible. Some browsers (Chrome) require user action to request media. Others do not.
        // Once approved, refreshing the page should be seamless.

        this.startMic()
            .catch( error => console.log( error ) );

    }

    bindEventListeners() {
        this.initMicButton.addEventListener( 'click', () => this.startMic() );
        //this.restartButton.addEventListener( 'click', () => this.restartSession() );

        document.addEventListener( 'keydown', e => {
            if ( ! this.tabnav.captureSpacebar ) {
                return;
            }

            if ( e.code == 'Space' ) {
                e.preventDefault();
                if ( e.repeat ) { return; }
                this.startListening();
            }
        });

        document.addEventListener( 'keyup', e => {
            if ( ! this.tabnav.captureSpacebar ) {
                return;
            }

            if ( e.code == 'Space' ) {
                e.preventDefault();
                this.stopListening();
            }
        });

        this.tabnav.tabs.forEach( el => {
            el.addEventListener( 'active_tab', event => {
                if ( event.target.id === 'step_welcome' && document.querySelectorAll( '#intake_form input:invalid').length ) {
                    setTimeout( () => {
                        this.tabnav.select( 'step_intake' );
                        alert( 'Form incomplete' );
                    }, 0 );
                }

                if ( event.target.id === 'step_interview' && ! this.interviewStarted ) {
                    this.startInterview();
                }

            });
        });

        this.responseAudio.addEventListener( 'ended', e => {
            if ( this.agentResponse.end || this.agentResponse.message.indexOf( '<<>>' ) !== -1 ) {
                this.tabnav.select( 'step_wrap' );
            }

        });


        this.tabnav.restartButton.addEventListener( 'click', e => {
            this.restartSession();
            this.tabnav.select(0);
        });
    }

    async startMic() {
        await this.audioCapture.media_stream();
        this.initMicButton.style.display = this.audioCapture.stream ? 'none' : '';
    }

    async startListening() {
        try {
            this.updateStatus('Listening...');
            await this.audioCapture.start();
            new VolumeMeter( this.audioCapture.stream, this.volumeMeter );
        } catch (error) {
            this.updateStatus('Error starting audio capture');
            console.error(error);
        }
    }

    async stopListening() {
        try {
            this.audioCapture.stop( async (audioBlob) => {
                //this.capturePlayer.src = URL.createObjectURL( audioBlob );
                await this.recognizeAudio( audioBlob );
            });

            this.updateStatus('Processing...');
        } catch (error) {
            this.updateStatus('Error processing audio');
            console.error(error);

        }
  }

    async recognizeAudio( audioBlob ) {
        //this.displayRecognizedText();
        this.updateStatus( 'Sending audio' );
        try {
            const file = new File( [audioBlob], 'input.wav' );
            //const file = URL.createObjectURL( audioBlob );
            const recognizedText = await this.speechRecognition.recognizeFromAudioFile( file );
            if ( recognizedText ) {
                //this.displayRecognizedText(recognizedText);
                this.updateStatus( 'Sending recognized text.' );
                this.sendTextToAgent(recognizedText);
            } else {
                //this.displayRecognizedText( 'No speech detected' );
                this.updateStatus('No speech detected');
            }
        } catch( error ) {
            this.updateStatus( 'Error recognizing audio' );
            console.error( error );
        }

    }

    async sendTextToAgent( recognizedText, skipHistory ) {
        try {
            const safetyAnalysis = await this.contentSafety.analyzeText(recognizedText);
            //this.contentSafetyStatus.innerHTML = this.contentSafety.displayAnalysis( safetyAnalysis );
            if (this.contentSafety.isSafe(safetyAnalysis)) {
                this.updateStatus( 'Sending to LLM' );
                const chatgptResponse = await this.api.user_conversation( recognizedText, this.chatHistory );
                try {
                    const response = this.agentResponse = {message: chatgptResponse };
                    this.updateStatus( 'Generating response audio' );
                    await this.synthesizeSpeech( response.message );
                    this.updateStatus( 'Playing response.' );
                    this.addChatHistory( recognizedText, response.message, skipHistory );
                } catch ( error ) {
                    this.updateStatus( 'Error processing response' );
                    console.error( error );
                }

            } else {
                this.updateStatus('Content flagged as unsafe');
            }
        } catch (error) {
            this.updateStatus('Error processing audio');
            console.error(error);
        }
    }

    async synthesizeSpeech( text ) {
        this.chatgptResponseAudio = await this.textToSpeech.synthesizeSpeech(text);
        this.playResponse();
        this.updateStatus('');
    }

    playResponse() {
        if (this.chatgptResponseAudio) {
            const url = URL.createObjectURL(this.chatgptResponseAudio);
            this.responseAudio.src = url;
            this.responseAudio.play();
        }
    }

    updateStatus(message) {
        this.statusElement.textContent = message;
    }

    displayRecognizedText(text) {
        this.recognizedTextElement.textContent = text ? text : '';
    }

    displayChatGPTResponse(response) {
        this.chatgptResponseElement.textContent = response;
    }

    addChatHistory( userText, agentText, skipHistory ) {
        const userMessage = {
            role: 'user',
            content: userText
        };

        const agentMessage = {
            role: 'assistant',
            content: agentText
        };

        this.chatHistory.push( userMessage );
        if ( !skipHistory ) {
            this.displayChatHistory( userMessage );
        }

        this.chatHistory.push( agentMessage );
        this.displayChatHistory( agentMessage );
    }

    displayChatHistory( message, noscroll ) {
        const row = document.createElement( 'div' );
        row.className = 'msg-row msgrole-' + message.role;

        const item = document.createElement( 'div' );
        item.className = 'msg';
        row.append( item );

        const content = document.createElement( 'span' );
        content.textContent = message.content;
        item.append( content );

        this.chatHistoryElement.append( row );

        if ( !noscroll ) {
            this.chatHistoryElement.scrollTop = this.chatHistoryElement.scrollHeight;
        }
    }

    restartSession() {
        this.interviewStarted = false;
        this.chatHistory = [];
        this.chatHistoryElement.innerHTML = '';
        //this.displayChatGPTResponse('');
        //this.contentSafetyStatus.innerHTML = '';
        //this.displayRecognizedText( '' );        
    }

    intakeData() {
        const intake = document.getElementById( 'intake_form' );
        const data = {};
        intake.querySelectorAll( 'input' ).forEach( el => {
            data[ el.name ] = el.value || '';
        });

        const input = ( key, fallback ) => data[key] || fallback || '';

        return {
            name: input( 'name', 'Guest' ),
            company: input( 'company' ),
            title: input( 'title'  ),
            industry: input( 'industry' ),
            location: input( 'location' ),
            email: input( 'email' )
        };
    }

    startInterview() {
        this.interviewStarted = true;
        this.chatHistory = [];
        let hello = 'Hello. Here is my information:\n\n';
        const data = this.intakeData();

        for ( const key in data ) {
            hello += `${key}: ${data[key]}\n`;
        }

        console.log( hello );

        this.sendTextToAgent( hello, true );

    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});

