export class TabNav {

	welcomeAudioUrl = 'https://matchmakerweb.z13.web.core.windows.net/mm-intro-sarah-v1.mp3';
	wrapAudioUrl = 'https://matchmakerweb.z13.web.core.windows.net/mm-wrapup-sarah-v1.mp3';

	init() {
		this.nav = Array.from( document.querySelectorAll( '#breadcrumbs li' ) );
		this.tabs = Array.from( document.querySelectorAll( '#tabs .tab' ) );
		//this.prevButton = document.getElementById( 'prev-button' );
		this.nextButton = document.getElementById( 'next-button' );
		this.hideNext = [ 'step_interview' ];

		this.restartButton = document.getElementById( 'restart-button' );
		this.showRestart = [ 'step_wrap' ];

		this.captureSpacebar = false;

		this.welcomeAudio = new Audio( this.welcomeAudioUrl );
		this.wrapAudio = new Audio( this.wrapAudioUrl );
		this.listeners();
		this.select();

	}


	listeners() {
		/*
		this.nav.forEach( el => {
			const id = el.getAttribute( 'data-tab' );
			if ( id ) {
				el.addEventListener( 'click', e => {
					this.select(id);
				});
			}
		});
		*/

		this.nextButton.addEventListener( 'click', e => this.select( e.target.getAttribute( 'data-tab' ) ) );
		//this.prevButton.addEventListener( 'click', e => this.select( e.target.getAttribute( 'data-tab' ) ) );

		this.tabs.forEach( el => {
			el.addEventListener( 'active_tab', e => this.playWelcomeAudio( e ) );
			el.addEventListener( 'active_tab', e => this.playWrapAudio( e ) );
			el.addEventListener( 'active_tab', e => this.toggleSpacebarCapture( e ) );
		});

		this.welcomeAudio.addEventListener( 'ended', () => this.nextButton.click() );
	}

	select( id ) {
		const first = !id;
		const isNum = Number.isInteger( id );

		this.tabs.forEach( ( el, num ) => {
			const active = ( first && num === 0 ) || isNum && num === id || el.id == id;
			el.classList.toggle( 'active', active );

			if ( active ) {
				this.triggerTabEvent( el );
			}
		});

		this.nav.forEach( ( el, num ) => {
			const active = ( first && num === 0 ) || isNum && num === id || el.getAttribute('data-tab') === id;
			el.classList.toggle( 'active', active );
		});

		this.updateButtons();
	}

	updateButtons() {
		const el = document.querySelector( '#tabs .tab.active' );
		if ( !el ) {
			this.select(0);
			return;
		}

		const id = el.id;
		let prev, next, cur;

		for ( let i in this.tabs ) {
			i = parseInt( i, 10 );
			if ( this.tabs[i].id === id ) {
				prev = i ? this.tabs[i-1] : null;
				next = i == this.tabs.length-1 ? null : this.tabs[1+i];
				cur = this.tabs[i];

				break;
			}
		}

		if ( this.prevButton ) {
			this.prevButton.classList.toggle( 'disabled', !prev );
			this.prevButton.setAttribute( 'data-tab', prev ? prev.id : '' );
		}

		if ( this.nextButton ) {
			this.nextButton.classList.toggle( 'disabled', !next );
			this.nextButton.setAttribute( 'data-tab', next ? next.id : '' );

			this.nextButton.classList.toggle( 'hidden', this.hideNext.indexOf( el.id ) !== -1 );
		}

		if ( this.restartButton ) {
			this.restartButton.classList.toggle( 'hidden', this.showRestart.indexOf( el.id ) === -1 );
		}
	}

	triggerTabEvent( el ) {
		const event = new Event('active_tab');
		el.dispatchEvent( event );
	}

	playWelcomeAudio( event ) {
		const audio = this.welcomeAudio;
		if ( event.target.id == 'step_welcome' ) {
			if ( audio.paused ) {
				audio.currentTime = 0;
			}
			audio.play();
		} else {
			audio.pause();
		}
	}

	playWrapAudio( event ) {
		const audio = this.wrapAudio;
		if ( event.target.id == 'step_wrap' ) {
			if ( audio.puased ) {
				audio.currentTime = 0;
			}
			audio.play();
		} else {
			audio.pause();
		}
	}

	toggleSpacebarCapture( event ) {
		this.captureSpacebar = event.target.id === 'step_interview';
	}
}

export default TabNav;